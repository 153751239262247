<template>
    <div class="baseMain">
        <div class="baseClass">
            <div class="baseClassLeft">
                <div class="baseClassLeftIn">
                    <div class="baseClassLeftInTop">
                        <el-input style="width: 300px;" placeholder="请输入要搜索的内容"
                                  suffix-icon="el-icon-search" v-model="filterText"></el-input>
                    </div>
                    <div class="baseClassLeftInMain nui-scroll">
                        <el-tree ref="tree" :data="detailTreeData" node-key="id" :default-expand-all="false"
                                 :default-expanded-keys="defaultExpandIds" :expand-on-click-node="false"
                                 :filter-node-method="filterNode" :highlight-current="true" :props="defaultProps"
                                 @node-expand="handleNodeExpand" @node-collapse="handleNodeCollapse">
                            <!--@node-click="lookDetail"-->
                            <template #default="{node,data}">
                                <div class="custom-tree-node">
                                    <div class="custom-tree-node-son" @click="lookDetail(data)">
                                        <el-icon :size="18" v-if="data.id == 0">
                                            <HomeFilled/>
                                        </el-icon>
                                        <el-icon :size="18" color="#E6A23C" v-else-if="data.id == -1">
                                            <user-filled/>
                                        </el-icon>
                                        <el-icon :size="18" color="#409EFF" v-else>
                                            <user-filled/>
                                        </el-icon>
                                        <span style="margin-left: 4px;">{{ data.name }}</span>
                                    </div>
                                    <template v-if="data.id != -1">
                                        <div v-if="(authorityStatus.organizationNew.show) || (data.id != 0 && authorityStatus.organizationDel.show) || (data.id != 0 && authorityStatus.organizationEdit.show)">
                                            <el-dropdown trigger="click" @command="handleCommand">
                                                <el-icon>
                                                    <MoreFilled/>
                                                </el-icon>
                                                <template #dropdown>
                                                    <el-dropdown-menu>
                                                        <el-dropdown-item :command="composeValue('add', node)"
                                                                          v-if="authorityStatus.organizationNew.show">
                                                            <span>创建子部门</span>
                                                        </el-dropdown-item>
                                                        <el-dropdown-item :command="composeValue('del', node)"
                                                                          v-if="data.id != 0 && authorityStatus.organizationDel.show">
                                                            删除
                                                        </el-dropdown-item>
                                                        <el-dropdown-item :command="composeValue('edit', node)"
                                                                          v-if="data.id != 0 && authorityStatus.organizationEdit.show">
                                                            编辑
                                                        </el-dropdown-item>
                                                    </el-dropdown-menu>
                                                </template>
                                            </el-dropdown>
                                            <!--<el-link type="primary">添加</el-link>-->
                                            <!--&nbsp;-->
                                            <!--<el-link type="danger">删除</el-link>-->
                                            <!--&nbsp;-->
                                            <!--<el-link type="primary">编辑</el-link>-->
                                        </div>
                                    </template>
                                </div>
                            </template>
                        </el-tree>
                    </div>
                </div>
            </div>
            <div class="baseClassRight" v-if="treeLookMsg != null">
                <div class="baseSearch clearfloat">
                    <div class="baseSearchOne">
                        <div class="baseSearchOneLeft">
                            <span>姓名/学工号：</span>
                        </div>
                        <div class="baseSearchOneRight">
                            <el-input v-model="searchForm.key_words" placeholder="请输入内容"></el-input>
                        </div>
                    </div>
                    <div class="baseSearchOne">
                        <el-button type="primary" style="margin-left: 20px;" @click="search">搜索</el-button>
                        <el-button @click="cleanAll">清空</el-button>
                    </div>
                </div>
                <div class="baseOperate clearfloat">
                    <div class="baseOperateOne2">
                        <span class="baseOperateText">已选 <strong
                                style="color: rgba(65, 148, 242, 1);">{{tableDataSel.length}}</strong> 位用户</span>
                    </div>
                    <div class="baseOperateOne2 userTeacherML">
                        <el-button type="primary" @click="openAddStaff('new')" v-if="authorityStatus.teacherNew.show">
                            添加教职工
                        </el-button>
                    </div>
                    <div class="baseOperateOne2 userTeacherML">
                        <el-button type="success" @click="openImport" v-if="authorityStatus.teacherImport.show">批量导入
                        </el-button>
                    </div>
                    <div class="baseOperateOne2 userTeacherML" v-if="authorityStatus.teacherExport.show">
                        <el-button @click="openExport">导出全部</el-button>
                    </div>
                    <div class="baseOperateOne2 userTeacherML">
                        <el-button type="warning" @click="openMoveStaff" v-if="authorityStatus.teacherChange.show">
                            移动部门
                        </el-button>
                    </div>
                    <div class="baseOperateOne2 userTeacherML" v-if="authorityStatus.teacherDel.show">
                        <el-button type="danger" @click="batchDel">批量删除</el-button>
                    </div>
                </div>
                <div class="baseTable" v-loading="tableLoading">
                    <publicTable :publicTableOtherHeight="searchHeight" :publicTableData="tableData"
                                 :publicTableConfigure="tableConfigure" @selAllDataFun="tableMsgFromSon($event)"
                                 ref="publicTableUserStudent">
                        <el-table-column prop="card_number" label="学工号"></el-table-column>
                        <el-table-column prop="name" label="姓名"></el-table-column>
                        <el-table-column prop="duties" label="职务"></el-table-column>
                        <el-table-column prop="orgs.name" label="部门"></el-table-column>
                        <el-table-column prop="phone" label="手机号"></el-table-column>
                        <el-table-column label="班级权限">
                            <template #default="scope">
                                <span v-if="scope.row.teacher_grade_name != ''">{{scope.row.teacher_grade_name_msg}} </span>
                                <el-link
                                        v-if="scope.row.teacher_grade_name != '' && scope.row.teacher_grade_name_list.length>5"
                                        class="tableButton"
                                        type="primary" @click.prevent="openAllClass(scope.row)">
                                    查看全部
                                </el-link>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作">
                            <template #default="scope">
                                <el-link class="tableButton" type="primary"
                                         @click.prevent="openAddStaff('edit',scope.row)"
                                         v-if="authorityStatus.teacherEdit.show">
                                    编辑
                                </el-link>
                                <el-link class="tableButton" type="danger" @click.prevent="delStaff(scope.row)"
                                         v-if="authorityStatus.teacherDel.show">
                                    删除
                                </el-link>
                            </template>
                        </el-table-column>
                    </publicTable>
                </div>
                <div class="basePage" style="margin-top: 4px;">
                    <publicPage ref="publicPageUserStudent" :publicPageTotal="{total:total}"
                                @pageChange="getTableData"></publicPage>
                </div>
            </div>
        </div>
        <el-dialog :title="departmentTitle" v-model="departmentDialog" width="600px" :close-on-click-modal="false">
            <el-form :model="treeForm" :rules="treeFormRules" ref="treeForm" label-width="100px">
                <el-form-item label="部门名称" prop="name">
                    <el-input v-model="treeForm.name"></el-input>
                </el-form-item>
            </el-form>
            <template #footer>
                <div class="dialog-footer">
                    <el-button type="primary" @click="saveTree">确 定</el-button>
                </div>
            </template>
        </el-dialog>
        <el-dialog :title="dialogTitle" v-model="dialogVisible" width="600px" :close-on-click-modal="false">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" v-if="dialogVisible">
                <el-form-item label="学工号" prop="card_number">
                    <el-input v-if="dialogTitle == '添加教职工'" v-model="ruleForm.card_number"></el-input>
                    <span v-else-if="dialogTitle == '编辑教职工'">{{ruleForm.card_number}}</span>
                </el-form-item>
                <el-form-item label="姓名" prop="name">
                    <el-input v-model="ruleForm.name"></el-input>
                </el-form-item>
                <el-form-item label="性别" prop="sex">
                    <el-radio-group v-model="ruleForm.sex">
                        <el-radio label="1">男</el-radio>
                        <el-radio label="2">女</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="职务">
                    <el-input v-model="ruleForm.duties"></el-input>
                </el-form-item>
                <el-form-item label="手机号" prop="phone">
                    <el-input v-model="ruleForm.phone"></el-input>
                </el-form-item>
                <el-form-item label="部门" prop="class_id">
                    <el-cascader v-model="ruleForm.class_id" :options="options" :props="props"
                                 placeholder="请选择部门"></el-cascader>
                </el-form-item>
                <el-form-item label="班级权限">
                    <el-tree style="width: 100%;" ref="jzgxsTree" :data="class_id_list" node-key="id"
                             default-expand-all
                             :expand-on-click-node="false" :highlight-current="true" show-checkbox :props="defaultProps"
                             :default-checked-keys="defaultCheckedKeys">
                        <template #default="{ node}">
                            <div class="custom-tree-node">
                                <span>{{ node.label }}</span>
                            </div>
                        </template>
                    </el-tree>
                </el-form-item>
            </el-form>
            <template #footer>
                <div class="dialog-footer">
                    <el-button type="primary" @click="saveAddStaffSave">确 定</el-button>
                </div>
            </template>
        </el-dialog>
        <el-drawer title="批量导入" v-model="importDialog" :close-on-click-modal="false" :destroy-on-close="true"
                   size="80%">
            <el-form :model="batchForm" :rules="batchRules" ref="batchForm" label-width="auto">
                <el-form-item label="批量导入教职工模板">
                    <a href="./files/批量导入教职工模板.xlsx" class="downMuban"
                       style="float: left;" download="批量导入教职工模板.xlsx">点击下载</a>
                </el-form-item>
                <el-form-item label="上传文件" prop="ruleUploadList">
                    <el-upload style="width: 400px;" ref="ruleUpload" drag
                               action="https://jsonplaceholder.typicode.com/posts/"
                               :on-change="ruleHandleChange" :before-remove="ruleHandleChange"
                               :file-list="batchForm.ruleFileList"
                               :auto-upload="false" :on-exceed="ruleHandleExceed"
                               list-type="text" accept=".xlsx" :limit="batchForm.ruleFileLimit">
                        <el-icon :size="67" color="#c0c4cc" style="margin-top: 20px;">
                            <UploadFilled/>
                        </el-icon>
                        <div>将文件拖到此处，或 <strong style="color: #0d84ff;">点击上传</strong></div>
                        <template #tip>
                            <div class="el-upload__tip">请使用上一项下载的模板进行上传</div>
                        </template>
                    </el-upload>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="saveImport">开始导入</el-button>
                </el-form-item>
            </el-form>
        </el-drawer>
        <el-dialog title="移动部门" v-model="movieDialog" width="600px" :close-on-click-modal="false">
            <el-tree ref="tree" :data="detailTreeData" node-key="id" default-expand-all
                     :expand-on-click-node="false" :highlight-current="true" :props="defaultProps">
                <template #default="{node,data}">
                    <div class="custom-tree-node">
                        <span>{{ data.name }}</span>
                        <el-link type="primary" @click="saveMoveStaff(node)">移动</el-link>
                    </div>
                </template>
            </el-tree>
        </el-dialog>
        <el-dialog title="全部班级权限" v-model="classListDialog" width="600px" :close-on-click-modal="false">
            <el-tag v-for="item in classListMsg" :key="item" style="margin: 8px;">
                {{ item }}
            </el-tag>
        </el-dialog>
    </div>
</template>

<script>

    import publicPage from '../../components/publicPage.vue'

    import publicTable from '../../components/publicTable.vue'

    import {MoreFilled, HomeFilled, UserFilled, UploadFilled} from '@element-plus/icons'

    import XLSX from 'xlsx'

    import {businessGet, businessPost, businessPut, businessDel} from '../../api/business.js'

    import {utilsUploadFile, utilsGetAuthority, utilsExportExcel, utilsNotificationJump} from '../../utils/utils.js'

    import Global from '../../Global.js'

    export default {
        name: "userTeacher",
        components: {
            publicPage,
            publicTable,
            MoreFilled,
            HomeFilled,
            UserFilled,
            UploadFilled
        },
        data() {
            return {
                userInfo: JSON.parse(localStorage.getItem(Global.GlobalLocalName2)),
                type: '2',
                filterText: '',
                detailTreeData: null,
                defaultExpandIds: [0],
                defaultProps: {
                    children: 'son',
                    label: 'name',
                },
                departmentDialog: false,
                departmentTitle: '',
                treeForm: {
                    name: ''
                },
                treeFormRules: {
                    name: [
                        {required: true, message: '该项不能为空', trigger: 'blur'},
                    ],
                },
                treeSelMsg: null,
                treeLookMsg: null,
                searchForm: {
                    key_words: '',
                },
                searchHeight: 300,
                tableConfigure: {
                    selection: true,
                },
                tableLoading: false,
                tableData: [],
                total: 0,
                tableDataSel: [],
                selMsg: null,
                dialogVisible: false,
                dialogTitle: '',
                class_id_list: [],
                defaultCheckedKeys: [],
                ruleForm: {
                    card_number: '',
                    name: '',
                    sex: '',
                    duties: '',
                    phone: '',
                    class_id: '',
                    teacher_grade: '',
                },
                rules: {
                    card_number: [
                        {required: true, message: '该项不能为空', trigger: 'blur'},
                    ],
                    name: [
                        {required: true, message: '该项不能为空', trigger: 'blur'},
                    ],
                    sex: [
                        {required: true, message: '该项不能为空', trigger: 'blur'},
                    ],
                    phone: [
                        {required: true, message: '该项不能为空', trigger: 'blur'},
                    ],
                    class_id: [
                        {required: true, message: '该项不能为空', trigger: 'blur'},
                    ],
                },
                importDialog: false,
                batchForm: {
                    ruleFileList: [],
                    ruleFileLimit: 1,
                    ruleUploadList: [],
                },
                batchRules: {
                    ruleUploadList: [
                        {type: 'array', required: true, message: '该项不能为空', trigger: 'blur'}
                    ],
                },
                movieDialog: false,
                options: [],
                props: {
                    value: 'id',
                    label: 'name',
                    children: 'son',
                    checkStrictly: true
                },
                authorityStatus: {
                    teacherNew: {
                        method: 'post',
                        url: '/admin/v1/teacher',
                        show: false,
                    },
                    teacherEdit: {
                        method: 'put',
                        url: '/admin/v1/teacher/{user_id}',
                        show: false,
                    },
                    teacherDel: {
                        method: 'delete',
                        url: '/admin/v1/teacher/{user_id}',
                        show: false,
                    },
                    teacherImport: {
                        method: 'post',
                        url: '/admin/v1/teacher/import',
                        show: false,
                    },
                    teacherChange: {
                        method: 'post',
                        url: '/admin/v1/teacher/change_class',
                        show: false,
                    },
                    teacherExport: {
                        method: 'get',
                        url: '/admin/v1/teacher_export',
                        show: false,
                    },
                    organizationNew: {
                        method: 'post',
                        url: '/admin/v1/organization_type2',
                        show: false,
                    },
                    organizationEdit: {
                        method: 'put',
                        url: '/admin/v1/organization_type2',
                        show: false,
                    },
                    organizationDel: {
                        method: 'delete',
                        url: '/admin/v1/organization_type2',
                        show: false,
                    }
                },
                classListDialog: false,
                classListMsg: [],
            }
        },
        watch: {
            filterText(val) {
                this.$refs.tree.filter(val);
            }
        },
        mounted() {
            utilsGetAuthority(this.authorityStatus)
            this.getTreeData();
            this.getStudentTreeData();
        },
        methods: {
            batchDel() {
                if (this.tableDataSel.length > 0) {
                    this.$confirm('此操作将批量删除选中的人员, 是否继续?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning',
                    }).then(() => {
                        let newArray = [];
                        this.tableDataSel.forEach(item => {
                            newArray.push(item.id)
                        })
                        let url = '/admin/v1/teacher/' + newArray.join(',');
                        businessDel(url, {}).then(res => {
                            if (res.data.status == 200) {
                                this.$message({
                                    showClose: true,
                                    message: res.data.message,
                                    type: 'success'
                                });
                                this.getTableData();
                            }
                            else {
                                this.$message({
                                    showClose: true,
                                    message: res.data.message,
                                    type: 'warning'
                                });
                            }
                        })
                    }).catch(() => {

                    })
                }
                else {
                    this.$message({
                        showClose: true,
                        message: '请先选择要删除的人员',
                        type: 'warning'
                    });
                }
            },
            openAllClass(val) {
                this.classListMsg = val.teacher_grade_name_list;
                this.classListDialog = true;
            },
            getStudentTreeData() {
                let url = '/admin/v1/organization?type=1';
                businessGet(url).then(res => {
                    if (res.data.status == 200) {
                        this.class_id_list = res.data.data;
                    }
                })
            },
            saveMoveStaff(val) {
                this.$confirm('此操作将所选人员移动到 ' + val.data.name + ', 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(() => {
                    let newArray = [];
                    this.tableDataSel.forEach(item => {
                        newArray.push(item.id)
                    })
                    let url = '/admin/v1/teacher/change_class';
                    let data = {
                        class_id: val.data.id,
                        user_ids: newArray.join(',')
                    }
                    businessPost(url, data).then(res => {
                        if (res.data.status == 200) {
                            this.$message({
                                showClose: true,
                                message: res.data.message,
                                type: 'success'
                            });
                            this.movieDialog = false;
                            this.getTableData();
                        }
                        else {
                            this.$message({
                                showClose: true,
                                message: res.data.message,
                                type: 'warning'
                            });
                        }
                    })
                }).catch(() => {

                })
            },
            openMoveStaff() {
                if (this.tableDataSel.length > 0) {
                    this.movieDialog = true;
                }
                else {
                    this.$message({
                        showClose: true,
                        message: '请先选择要移动的人员',
                        type: 'warning'
                    });
                }
            },
            openExport() {
                let url = '/admin/v1/teacher?is_export=1&type=' + this.type + '&org_id=' + this.treeLookMsg.id;
                if (this.searchForm.key_words) {
                    url = url + '&key_words=' + this.searchForm.key_words
                }
                utilsExportExcel(url)
            },
            exportExcel() {
                this.$confirm('此操作将按照搜索条件导出数据, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(() => {
                    //数据表格
                    let exportList = [];
                    var aoa = [
                        ['支付宝账号', '支付宝账号姓名', '转账金额', '转账人', '转账时间', '转账状态', '备注'],
                    ];
                    let dayinName = ['receiverId', 'receiverName', 'transAmount', 'operaterName', 'fuTransDate', 'statusName', 'remark']
                    exportList.forEach(item => {
                        let a = []
                        dayinName.forEach(item2 => {
                            a.push(item[item2])
                        })
                        aoa.push(a)
                    })
                    //创建book
                    var wb = XLSX.utils.book_new();
                    var ws = XLSX.utils.aoa_to_sheet(aoa);
                    //设置列宽
                    ws['!cols'] = [
                        {width: 20},
                        {width: 20},
                        {width: 20},
                        {width: 20},
                        {width: 20},
                        {width: 20},
                    ];
                    XLSX.utils.book_append_sheet(wb, ws, "sheet1");
                    //输出
                    let exportName = "转账记录.xlsx"
                    XLSX.writeFile(wb, exportName);
                }).catch(() => {
                })
            },
            saveImport() {
                this.$refs.batchForm.validate((valid) => {
                    if (valid) {
                        utilsUploadFile(this.batchForm.ruleUploadList[0].raw, 'import').then(res => {
                            let url = '/admin/v1/teacher/import';
                            let data = {
                                file_id: res.file_id,
                                type: 2,
                            }
                            businessPost(url, data).then(res2 => {
                                if (res2.data.status == 200) {
                                    utilsNotificationJump(res2.data.message, '/systemImport?module=teacher')
                                    this.importDialog = false;
                                    this.getTableData();
                                }
                                else {
                                    this.$message({
                                        showClose: true,
                                        message: res2.data.message,
                                        type: 'warning'
                                    });
                                }
                            })
                        })
                        // let url = '/admin/v1/teacher';
                        // let data = {
                        //     type: this.type,
                        //     card_number: this.ruleForm.card_number,
                        //     name: this.ruleForm.name,
                        //     sex: this.ruleForm.sex,
                        //     duties: this.ruleForm.duties,
                        //     phone: this.ruleForm.phone,
                        //     class_id: this.ruleForm.class_id[this.ruleForm.class_id.length - 1],
                        //     teacher_grade: this.ruleForm.teacher_grade.join(','),
                        // }
                        // businessPost(url, data).then(res => {
                        //     if (res.data.status == 200) {
                        //         this.$message({
                        //             showClose: true,
                        //             message: res.data.message,
                        //             type: 'success'
                        //         });
                        //         this.dialogVisible = false;
                        //         this.getTableData();
                        //     }
                        //     else {
                        //         this.$message({
                        //             showClose: true,
                        //             message: res.data.message,
                        //             type: 'warning'
                        //         });
                        //     }
                        // })
                    }
                })
            },
            openImport() {
                this.batchForm.ruleFileList = [];
                this.batchForm.ruleFileLimit = 1;
                this.batchForm.ruleUploadList = [];
                this.importDialog = true;
            },
            ruleHandleExceed(files, fileList) {
                this.$message.warning(`当前限制选择 ` + this.batchForm.ruleFileLimit + ` 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
            },
            ruleHandleChange(file, fileList) {
                this.batchForm.ruleUploadList = fileList;
                // let reader = new FileReader();
                // reader.readAsBinaryString(file.raw);
                // reader.onload = evt => {
                //     let data = evt.target.result; // 读到的数据
                //     let wb = XLSX.read(data, {type: "binary"});
                //     let newArray = wb.Sheets.Sheet1;
                //     let noK = ['!margins', '!ref', 'A1', 'B1', 'C1', 'D1'];
                //     let searchList = [];
                //     for (let k in newArray) {
                //         if (noK.indexOf(k) == -1) {
                //             let kNameList = k.split('')
                //             let num = kNameList[1] - 2;
                //             if (searchList[num]) {
                //                 searchList[num][this.controlList[kNameList[0]]] = newArray[k].w;
                //             }
                //             else {
                //                 searchList[num] = {};
                //                 searchList[num][this.controlList[kNameList[0]]] = newArray[k].w;
                //             }
                //         }
                //     }
                //     this.batchForm.tableData = searchList;
                //     // let outdata = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]);
                //     // console.log(outdata);
                // };
            },
            delStaff(val) {
                this.$confirm('此操作将删除该人员(' + val.name + '), 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(() => {
                    let url = '/admin/v1/teacher/' + val.id;
                    businessDel(url, {}).then(res => {
                        if (res.data.status == 200) {
                            this.$message({
                                showClose: true,
                                message: res.data.message,
                                type: 'success'
                            });
                            this.getTableData();
                        }
                        else {
                            this.$message({
                                showClose: true,
                                message: res.data.message,
                                type: 'warning'
                            });
                        }
                    })
                }).catch(() => {

                })
            },
            saveAddStaffSave() {
                this.$refs.ruleForm.validate((valid) => {
                    if (valid) {
                        let url = '/admin/v1/teacher';
                        let newArray = this.$refs.jzgxsTree.getCheckedKeys();
                        let data = {
                            type: this.type,
                            card_number: this.ruleForm.card_number,
                            name: this.ruleForm.name,
                            sex: this.ruleForm.sex,
                            duties: this.ruleForm.duties,
                            phone: this.ruleForm.phone,
                            class_id: this.ruleForm.class_id[this.ruleForm.class_id.length - 1],
                            teacher_grade: newArray.join(','),
                        }
                        for (let i in data) {
                            if (data[i] == "") {
                                delete data[i];
                            }
                        }
                        data.teacher_grade = newArray.join(',');
                        if (this.dialogTitle == '添加教职工') {
                            businessPost(url, data).then(res => {
                                if (res.data.status == 200) {
                                    this.$message({
                                        showClose: true,
                                        message: res.data.message,
                                        type: 'success'
                                    });
                                    this.dialogVisible = false;
                                    this.getTableData();
                                }
                                else {
                                    this.$message({
                                        showClose: true,
                                        message: res.data.message,
                                        type: 'warning'
                                    });
                                }
                            })
                        }
                        else if (this.dialogTitle == '编辑教职工') {
                            url = url + '/' + this.selMsg.id;
                            businessPut(url, data).then(res => {
                                if (res.data.status == 200) {
                                    this.$message({
                                        showClose: true,
                                        message: res.data.message,
                                        type: 'success'
                                    });
                                    this.dialogVisible = false;
                                    this.getTableData();
                                }
                                else {
                                    this.$message({
                                        showClose: true,
                                        message: res.data.message,
                                        type: 'warning'
                                    });
                                }
                            })
                        }
                    }
                })
            },
            openAddStaff(sort, val) {
                this.ruleForm.card_number = '';
                this.ruleForm.name = '';
                this.ruleForm.sex = '';
                this.ruleForm.duties = '';
                this.ruleForm.phone = '';
                this.ruleForm.class_id = '';
                this.defaultCheckedKeys = [];
                if (sort == 'new') {
                    this.dialogTitle = '添加教职工';
                    this.dialogVisible = true;
                }
                else if (sort == 'edit') {
                    this.selMsg = val;
                    this.ruleForm.card_number = this.selMsg.card_number;
                    this.ruleForm.name = this.selMsg.name;
                    this.ruleForm.sex = this.selMsg.sex+'';
                    this.ruleForm.duties = this.selMsg.duties;
                    this.ruleForm.phone = this.selMsg.phone;
                    this.ruleForm.class_id = this.selMsg.class_id;
                    if (this.selMsg.teacher_grade) {
                        let newArray = this.selMsg.teacher_grade.split(',');
                        let teacher_grade = [];
                        newArray.forEach(item => {
                            teacher_grade.push(item * 1);
                        })
                        this.defaultCheckedKeys = teacher_grade;
                    }
                    this.dialogTitle = '编辑教职工';
                    this.dialogVisible = true;
                }
                this.$nextTick(() => {
                    let dom = document.querySelectorAll('.el-cascader');
                    for (let i = 0; i < dom.length; i++) {
                        dom[i].style.width = '100%';
                    }
                })
            },
            tableMsgFromSon(event) {
                this.tableDataSel = event;
            },
            cleanAll() {
                this.searchForm.key_words = '';
                this.search();
            },
            search() {
                this.$refs.publicPageUserStudent.currentPage = 1;
                this.getTableData();
            },
            getTableData() {
                let url = '/admin/v1/teacher?type=' + this.type + '&org_id=' + this.treeLookMsg.id + '&page=' + this.$refs.publicPageUserStudent.currentPage + '&page_pre=' + this.$refs.publicPageUserStudent.pageSize;
                if (this.searchForm.key_words) {
                    url = url + '&key_words=' + this.searchForm.key_words
                }
                this.tableLoading = true;
                businessGet(url).then(res => {
                    this.tableLoading = false;
                    if (res.data.status == 200) {
                        this.total = res.data.data.count;
                        let tableData = res.data.data.user_list;
                        tableData.forEach(item => {
                            if (item.teacher_grade_name != '') {
                                item.teacher_grade_name_list = item.teacher_grade_name.split(',');
                                let num = 5;
                                if (item.teacher_grade_name_list.length < 5) {
                                    num = item.teacher_grade_name_list.length;
                                }
                                let newArray = [];
                                for (let i = 0; i < num; i++) {
                                    newArray.push(item.teacher_grade_name_list[i])
                                }
                                item.teacher_grade_name_msg = newArray.join(',');
                                if (num > 5) {
                                    item.teacher_grade_name_msg += ' 等' + item.teacher_grade_name_list.length + '个'
                                }
                            }
                        })
                        this.tableData = tableData;
                    }
                })
            },
            filterNode(value, data) {
                if (!value) return true;
                return data.name.indexOf(value) !== -1;
            },
            composeValue(sort, node) {
                return {
                    'sort': sort,
                    'node': node,
                }
            },
            getAllClass() {
                let url = '/admin/v1/organization/lowest';
                businessGet(url).then(res => {
                    if (res.data.status == 200) {
                        this.class_id_list = res.data.data;
                    }
                    else {
                        this.$message({
                            showClose: true,
                            message: res.data.message,
                            type: 'warning'
                        });
                    }
                })
            },
            lookDetail(data) {
                // this.treeLookMsg = JSON.parse(JSON.stringify(data));
                this.treeLookMsg = data;
                this.$nextTick(() => {
                    let baseSearchDom = document.querySelector('.baseSearch');
                    let baseOperateDom = document.querySelector('.baseOperate');
                    let searchHeight = baseSearchDom.offsetHeight + baseOperateDom.offsetHeight + 30;
                    this.searchHeight = searchHeight;
                    this.cleanAll();
                })
            },
            saveTree() {
                this.$refs.treeForm.validate((valid) => {
                    if (valid) {
                        let url = '/admin/v1/organization';
                        let data = {
                            name: this.treeForm.name,
                            type: this.type,
                        }
                        if (this.departmentTitle == '添加子部门') {
                            data.parent_id = this.treeSelMsg.id;
                            businessPost(url, data).then(res => {
                                if (res.data.status == 200) {
                                    this.$message({
                                        showClose: true,
                                        message: res.data.message,
                                        type: 'success'
                                    });
                                    this.getTreeData();
                                    this.departmentDialog = false;
                                }
                                else {
                                    this.$message({
                                        showClose: true,
                                        message: res.data.message,
                                        type: 'warning'
                                    });
                                }
                            })
                        }
                        else if (this.departmentTitle == '修改部门名称') {
                            url = url + '/' + this.treeSelMsg.id;
                            businessPut(url, data).then(res => {
                                if (res.data.status == 200) {
                                    this.$message({
                                        showClose: true,
                                        message: res.data.message,
                                        type: 'success'
                                    });
                                    this.getTreeData();
                                    this.departmentDialog = false;
                                }
                                else {
                                    this.$message({
                                        showClose: true,
                                        message: res.data.message,
                                        type: 'warning'
                                    });
                                }
                            })
                        }
                    }
                })
            },
            handleCommand(command) {
                this.treeSelMsg = command.node.data;
                if (command.sort == 'add') {
                    this.departmentTitle = '添加子部门';
                    this.treeForm.name = '';
                    this.departmentDialog = true;
                }
                if (command.sort == 'del') {
                    this.$confirm('删除后该部门成员将纳入“未分组”管理，是否确认删除该部门?', '提示', {
                        confirmButtonText: '确认',
                        cancelButtonText: '取消',
                        type: 'warning',
                    }).then(() => {
                        let url = '/admin/v1/organization' + '/' + this.treeSelMsg.id;
                        businessDel(url, {}).then(res => {
                            if (res.data.status == 200) {
                                this.$message({
                                    showClose: true,
                                    message: res.data.message,
                                    type: 'success'
                                });
                                this.getTreeData();
                            }
                            else {
                                this.$message({
                                    showClose: true,
                                    message: res.data.message,
                                    type: 'warning'
                                });
                            }
                        })
                    }).catch(() => {

                    })
                }
                if (command.sort == 'edit') {
                    this.departmentTitle = '修改部门名称';
                    this.treeForm.name = this.treeSelMsg.name;
                    this.departmentDialog = true;
                }
            },
            handleNodeExpand(data) {
                let flag = false
                this.defaultExpandIds.some(item => {
                    if (item === data.id) {
                        flag = true
                        return true
                    }
                })
                if (!flag) {
                    this.defaultExpandIds.push(data.id)
                }
            },
            handleNodeCollapse(data) {
                this.defaultExpandIds.some((item, i) => {
                    if (item === data.id) {
                        this.defaultExpandIds.splice(i, 1)
                    }
                })
            },
            getTreeData() {
                let url = '/admin/v1/organization?type=' + this.type;
                businessGet(url).then(res => {
                    if (res.data.status == 200) {
                        let detailTreeData = JSON.parse(JSON.stringify(Global.GlobalTree));
                        detailTreeData[0].name = this.userInfo.school_name;
                        if (res.data.data.length > 0) {
                            detailTreeData[0].son = res.data.data
                        }
                        this.detailTreeData = detailTreeData;
                        this.options = res.data.data;
                        this.lookDetail(this.detailTreeData[0])
                    }
                })
            }
        }
    }
</script>

<style scoped>

    @import "../../assets/css/base.css";

    .userTeacherML {
        margin-left: 16px;
    }

    .el-upload__text {
        width: 100%;
        height: 50px;
    }

</style>
